import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { PageResponse } from '@core/shared/domain';
import { AddressListItem } from '@mp/organization-master-data/addresses/domain';

export const AddressesActions = createActionGroup({
  source: 'Organization Master Data - Addresses',
  events: {
    fetchAddresses: props<{ searchTerm?: string }>(),
    fetchAddressesSuccess: props<{ addressesPageResponse: PageResponse<AddressListItem> }>(),
    fetchAddressesError: emptyProps(),

    upsertAddressToList: props<{ address: AddressListItem }>(),
  },
});
