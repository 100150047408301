import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PageResponse } from '@core/shared/domain';
import { QueryParams } from '@core/shared/util';
import { AddressListItem } from '@mp/organization-master-data/addresses/domain';

@Injectable({ providedIn: 'root' })
export class AddressesService {
  private readonly baseUrl = '/api/m/organizationmasterdata/addresses';

  constructor(private readonly http: HttpClient) {}

  fetchAddresses(searchTerm?: string): Observable<PageResponse<AddressListItem>> {
    const params = QueryParams.build().param('searchTerm', searchTerm).withoutPagination().toHttpParams();

    return this.http.get<PageResponse<AddressListItem>>(this.baseUrl, { params });
  }
}
