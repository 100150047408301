import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { AddressListItem } from '@mp/organization-master-data/addresses/domain';

import { AddressesActions } from './addresses.actions';

export const addressesFeatureKey = 'mpomd-core-addresses';

type AddressesEntityState = EntityState<AddressListItem>;

export const addressesEntityAdapter = createEntityAdapter<AddressListItem>({
  selectId: ({ addressId }: AddressListItem) => addressId,
});

export interface AddressesState {
  addresses: AddressesEntityState;
  addressesLoaded: boolean;
}

export const initialState: AddressesState = {
  addresses: addressesEntityAdapter.getInitialState(),
  addressesLoaded: true,
};

export const addressesReducer = createReducer(
  initialState,
  on(
    AddressesActions.fetchAddresses,
    (state: AddressesState): AddressesState => ({
      ...state,
      addresses: addressesEntityAdapter.getInitialState(),
      addressesLoaded: false,
    }),
  ),
  on(
    AddressesActions.fetchAddressesSuccess,
    (state: AddressesState, { addressesPageResponse: { data } }): AddressesState => ({
      ...state,
      addresses: addressesEntityAdapter.setAll(data, state.addresses),
      addressesLoaded: true,
    }),
  ),
  on(
    AddressesActions.fetchAddressesError,
    (state: AddressesState): AddressesState => ({
      ...state,
      addresses: addressesEntityAdapter.getInitialState(),
      addressesLoaded: true,
    }),
  ),

  on(
    AddressesActions.upsertAddressToList,
    (state: AddressesState, { address }): AddressesState => ({
      ...state,
      addresses: addressesEntityAdapter.upsertOne(address, state.addresses),
    }),
  ),
);

export const { selectAll: selectAllAddresses } = addressesEntityAdapter.getSelectors();
