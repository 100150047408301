import { EnvironmentProviders, importProvidersFrom, makeEnvironmentProviders } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AddressesEffects, AddressesFacade, addressesFeatureKey, addressesReducer } from '../store';

export function provideAddressesDataAccess(): EnvironmentProviders[] {
  return [
    importProvidersFrom(StoreModule.forFeature(addressesFeatureKey, addressesReducer)),
    importProvidersFrom(EffectsModule.forFeature([AddressesEffects])),
    makeEnvironmentProviders([AddressesFacade]),
  ];
}
